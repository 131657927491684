<template lang="pug">
    section#listagem
        .wrapper
            ul
                li(v-for="noticia in noticias")
                    router-link(:to="{ name: 'Noticia', params: { slug: noticia.slug }, query: { backTo } }")
                        Imagem(:contents="noticia.thumbnail").img
                            //- .tag
                                p Categoria
                        .info
                            h3 {{ noticia.title }}
                            span {{ getPublished(noticia) }}
                            p {{ noticia.description }}
                            button +
</template>

<script>
import { components } from '@/mixins/components'
import qs from 'qs'

export default {
  name: 'section-listagem',
  props: {
    noticias: {
      type: Array,
      required: true
    }
  },
  components,
  computed: {
    backTo () {
      return qs.stringify({
        to: { name: 'Noticias' },
        page: 'às Notícias'
      })
    }
  },
  methods: {
    getPublished (noticia) {
      if (noticia.published_at) { return noticia.published_at.replace(/(\d{4})-(\d{2})-(\d{2}).*/gi, '$3/$2/$1') }
      return noticia.created_at.replace(/(\d{4})-(\d{2})-(\d{2}).*/gi, '$3/$2/$1')
    }
  }
}
</script>

<style lang="stylus" scoped src="./Listagem.styl"></style>
