<template lang="pug">
	main#noticias
		BannerGeral(:contents="bannerContent", v-if="!$store.state.loading")
		//- Destaques
		Listagem(:noticias="noticias", v-if="!$store.state.loading")
</template>

<script>
import pagina from '@/mixins/pagina'
// import Destaques from '@sections/Noticias/Destaques/Destaques'
import Listagem from '@sections/Noticias/Listagem/Listagem'

export default {
  name: 'view-noticias',
  mixins: [pagina],
  components: {
    // Destaques,
    Listagem
  },
  data () {
    return {
      formulario: {
        search: '',
        categoria: null
      },
      categorias: [],
      noticias: []
    }
  },
  computed: {
    bannerContent () {
      return {
        content: {
          title: { pt: this.pagina.title }
          // formulario: this.formulario,
          // categorias: this.categorias
        }
      }
    }
  },
  mounted () {
    this.loadCategorias()
    this.loadNoticias()
  },
  methods: {
    loadCategorias () {

    },
    loadNoticias () {
      this.$axios
        .get('noticias')
        .then(response => this.noticias = response.data)
    },
    async loadNoticia (slug) {
      return await this.$axios.get(`noticias/${slug}`).data
    },
    send () {
      console.log(this.formulario)
    }
  }
}
</script>

<style lang="stylus" scoped src="./Noticias.styl"></style>
